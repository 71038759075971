@use "setting" as *;

/* ======================================================================
 p-404
====================================================================== */
.sec_01 {
  @include sp {
  }
  .sec_container {
    margin: 55px auto 0;
    padding: 122px 46px 49px 54px;
    width: 1120px;
    border-radius: 20px;
    background: $white;

    @include sp {
      margin: 40px auto 0;
      padding: 95px 15px 50px;
      width: 100%;
      border-radius: 0;
    }
  }
  .sec_ttl_404 {
    &::before {
      top: -60px;
      left: 50%;
      width: 61px;
      height: 55px;
      background: url(#{$img_dir}/p-404/sec_ttl_404.svg) no-repeat;
      transform: translate(-50%, 0);

      @include sp {
        top: -62px;
        width: 50px;
        height: 45px;
        background: url(#{$img_dir}/p-404/sp/sec_ttl_404.svg) no-repeat;
      }
    }
  }
  .flex {
    display: flex;
    margin: 30px auto 0;

    @include sp {
      flex-direction: column-reverse;
      margin: 20px auto 0;
    }
  }
  .item + .item {
    margin: 5px 0 0;

    @include sp {
    }
  }
  .txt_1 {
    margin: 30px 0 0;

    @include sp {
      margin: 10px 0 0;
    }
  }
  .pic {
    margin: 0 0 0 40px;

    @include sp {
      margin: 0 auto 10px;
      width: 345px;
      height: 228px;
    }
    .img {
      @include sp {
        width: 100%;
      }
    }
  }

  .btn_return_top {
    margin: 50px auto 0;

    @include sp {
      margin: 35px auto 0;
    }
  }
}

.mefical_info_block {
  margin: -10px 0 0;

  @include sp {
    margin: 165px auto 0;
  }
}
